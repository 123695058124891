import { Translation } from "./Translation";

export const de: Translation = {
    navigation: {
        left: {
            home: "Startseite",
            onetimeEmptying: "Einmalleerung",
            seriesEmptying: "Serienleerung",
            balancesAndInvoices: "Guthaben & Transaktionen",
            bannerText: "Einmalleerung am nächsten Leerungstag?",
            bannerButtonText: "1-Klick-Leerung"
        },
        top: {
            languageChooserLabels: {
                en: 'EN',
                de: 'DE'
            },
            myData: "Meine Daten",
            myContainers: "Meine Container",
            myOrganisation: "Meine Organisation",
            administration: "Administration",
            notificationSettings: "Benachrichtigungseinstellungen",
            helpAndContact: "Hilfe & Kontakt",
            login: "Einloggen",
            logout: "Ausloggen"
        }
    },
    footer: {
        footerText: "© {current-year} KVA Thurgau. Alle Rechte vorbehalten.",
        footerLinks: {
            termsConditions: "AGBs",
            imprint: "Impressum",
            dataProtection: "Datenschutz",
            helpAndContact: "Hilfe & Kontakt"
        }
    },
    payment: {
        transactionNames: {
            payForSingleEmptying: 'Einmalleerung am {date}',
            payForMultipleEmptyings: 'Serienleerung ({nof-emptyings} Leerungen)'
        }
    },
    components: {
        containers: {
            header: "Container",
            dummy: {
                infoText: "Erteile dem Abfall eine Abfuhr!",
                registerContainerButtonText: "Container hinzufügen",
            },
            noEmptyingScheduled: 'Keine Leerung eingeplant',
            noCompletedEmptying: 'Keine vergangenen Leerungen',
            noMoreEmptying: 'Keine weiteren Leerungen',
            pending: 'In Bearbeitung',
            recordedOn: 'Erfasst am ',
            emptyingsNumberTooltip: 'Anzahl der angeplanten Leerungen',
            infoTexts: {
                noRemovalDayAssigned: 'Wir haben dem Container noch keinen Leerungstag zugeordnet und kümmern uns schnellstmöglich darum. Bitte versuchen Sie es später erneut.',
                emptyingScheduledOnThisDate: 'Es wurde bereits eine Leerung für dieses Datum eingeplant.',
            },
            submenu: {
                changeContainerData: 'Containerdaten bearbeiten',
                orderOneTimeEmptying: 'Einmalleerung bestellen'
            }
        },
        notificationsForm: {
            header: "Benachrichtigungseinstellungen",
            emailNotifications: "E-Mail Benachrichtigung",
            orderConfirmation: "Bestellbestätigung",
            orderConfirmationTooltip: "Anzahl der angeplanten Leerungen",
            emptyingReminder: "Leerungserinnerung",
            emptyingReminderEmailTooltip: "Erinnerung für anstehende Containerleerungen. Wird am Tag vor Containerleerung versendet.",
            emptyingReminderSmsTooltip: "Erinnerung für anstehende Containerleerungen. Wird am Tag vor Containerleerung versendet.",
            emptyingConfirmation: "Leerungsbestätigung",
            emptyingConfirmationTooltip: "Bestätigung, dass eine Containerleerung erfolgte.",
            smsNotifications: "SMS Benachrichtigung",
            differentEmailText: "Um eine andere E-Mail-Adresse einzugeben, klicken Sie bitte in das Feld.",
            differentMobileText: "Um eine andere Mobiltelefon-Nummer einzugeben, klicken Sie bitte in das Feld."
        },
        loaders: {
            loading: 'Laden...',
            postingAccountDataChangeRequest: 'Antrag zur Datenänderung wird verarbeitet...',
            booking: 'Bestellen...'
        },
        tables: {
            noEntriesAvailable: 'Keine Einträge vorhanden'
        }
    },
    pages: {
        home: {
            welcome: {
                header: "e-Container Portal"
            },
            containerInfobox: {
                infoText: "Um das e-Container Portal nutzen zu können, fügen Sie Ihre Container hinzu.",
                buttonText: "Container hinzufügen"
            },
            helpInfobox: {
                infoText: "Um alle Funktionen des e-Container Portals kennenzulernen, sehen Sie sich die Anleitungsvideos an.",
                buttonText: "Anleitungsvideos ansehen"
            },
            organizationInfobox: {
                infoText: "Wollen Sie Ihren Mitarbeitenden die Möglichkeit zur Leerungsverwaltung oder zur Einsicht geben?",
                buttonText: "Mitarbeitende hinzufügen"
            },
            notificationsInfobox: {
                infoText: "Wollen Sie an anstehende Leerungen erinnert oder über erfolgte Leerungen informiert werden?",
                buttonText: "Benachrichtigungen einstellen"
            },
            emptyingsOverviewSection: {
                header: "Leerungskalender",
                allContainersOption: "Alle Container",
                tooltipAddEmptying: "Leerung bestellen",
                tooltipCancelEmptying: "Leerung stornieren",
            },
            creditSection: {
                header: "Guthaben",
                creditInfoCard: {
                    currency: "CHF",
                    emptyings: "Leerungen",
                    buttonText: "Aufladen",
                    infoText: "Sie können zur Leerungsbestellung Ihr Guthaben oder andere Zahlungsmittel nutzen.",
                },
                topUpOrderLineText: 'Guthaben aufladen',
                topUpModal: {
                    header: 'Guthaben aufladen',
                    topUpAmount: 'Aufladebetrag',
                    meansOfPayment: 'Zahlungsmittel',
                    maxAmountWarning: 'Warnung: Maximal <1>{{textValue}}</1> CHF zulässig.',
                    payByInvoiceMsg: {
                        title: 'Rechnung bezahlen',
                        text: 'Um die Aufladung Ihres Guthabens abzuschliessen, überweisen Sie uns den Rechnungsbetrag. Die Aufladung des Guthabens erfolgt bei Zahlungseingang. Die Rechnung für die Guthabenaufladung wird Ihnen in einigen Minuten im Bereich «Guthaben & Transaktionen» unter «Rechnungen» bereitgestellt.',
                        primaryBtn: 'Rechnungen',
                        secondaryBtn: 'Schliessen'
                    }
                }
            },
            cancelEmptyingModal: {
                header: 'Leerung stornieren',
                allContainersOption: "Alle Container",
                cancelationTextOnSingleDate: 'Wollen Sie die Leerung am <1>{{textValue}}</1> wirklich stornieren?',
                cancelationTextOnMultipleDates: 'Wollen Sie die ausgewählten <1>{{textValue}}</1> Leerungen wirklich stornieren?'
            }
        },
        emptying: {
            selection: 'Auswahl: ',
            emptyings: 'Leerungen',
            dirtyFormMessage: {
                title: 'Ungespeicherte Konfiguration',
                text: 'Es wurden ungespeicherte Daten festgestellt, die beim Verlassen dieser Seite entfernt werden.',
                labels: {
                    proceed: 'Seite verlassen',
                    stay: 'Abbrechen'
                }
            },
            oneclick: {
                header: "1-Klick-Leerung",
                subheader: "Hier können Sie einmalige Leerungen zum nächsten Leerungstag bestellen."
            },
            onetime: {
                header: "Einmalleerung",
                subheader: "Hier können Sie einmalige Leerungen für jeweils einen Ihrer Container bestellen.",
                subheaderOrderView: "Sie können bestellte Leerungen jederzeit bis 22:00 Uhr des Vortags stornieren. Die Kosten werden anschliessend automatisch Ihrem Guthabenkonto gutgeschrieben.",
                emptyingDateSection: {
                    header: 'Leerungsdatum',
                    currency: 'CHF',
                    costs: 'Kosten:',
                },
            },
            series: {
                header: 'Serienleerung',
                subheader: 'Hier können Sie regelmässige Leerungen für Ihre Container planen.',
                buttonPlanEmptying: 'Serienleerung planen',
                serialPlanning: {
                    header: 'Serienplanung',
                    tooltip: "Hier können Sie die geplanten Leerungstermine bearbeiten oder eine Serienleerung stornieren.",
                    container: 'Container',
                    seriesRule: 'Serienregel',
                    emptyingsNumber: 'Leerungen',
                },
                modalPlanSerialEmptying: {
                    header: 'Serienleerung planen',
                    container: 'Container',
                    weekday: 'Wochentag',
                    weekdayTooltip: 'Der auszuwählende Wochentag ergibt sich durch die Routenplanung der KVA Thurgau.',
                    inheritInputFromPreviousContainer: 'Gleiche Angaben wie für vorherigen Container übernehmen',
                    rhythm: 'Rhythmus',
                    weekly: 'Wöchentlich',
                    biweekly: 'Alle 2 Wochen',
                    fourweekly: 'Alle 4 Wochen',
                    period: 'Zeitraum',
                    periodTooltip: 'Sie können die Leerungstermine nach Klick auf "Planen" noch über die Planungsübersicht bearbeiten.',
                    entireYearCollection: 'Ich wünsche eine Abholung für das gesamte Jahr',
                    flexibleEmptyingDesired: 'Ich wünsche eine flexible Abholung',
                    start: 'Start',
                    end: 'Ende',
                    addPeriod: 'Zeitraum hinzufügen',
                    costs: 'Kosten:',
                    currency: 'CHF',
                    noPossibleDatesFound: 'Keine möglichen Daten gefunden.',
                },
                modalEditSerialEmptying: {
                    header: 'Leerungstermine bearbeiten',
                    container: 'Container',
                    weekday: 'Wochentage',
                    emptyingDates: 'Leerungstermine',
                },
                modalCancelSerialEmptying: {
                    header: 'Serienleerung stornieren',
                    confirmDeleteText: 'Wollen Sie die geplante Serienleerung für <1>{{textValue}}</1> wirklich löschen?',
                },
            },
            order: {
                header: "Bestellung",
                credit: "Guthaben",
                creditTooltip: "Bestellungen können mit dem Guthabenkonto (falls ausreichender Betrag vorhanden) oder anderen Zahlungsmitteln bezahlt werden.",
                currency: "CHF",
                container: "Container",
                date: "Datum",
                priceWithVat: "(Preis inkl. Mwst.)",
                total: "Summe",
                emptyings: "Leerungen",
                edit: 'Bestellung bearbeiten'
            },
            orderConfirmation: {
                header: 'Bestellbestätigung',
                oneTimeEmptyingFor: 'Die Leerung für ',
                serialEmptyingFor: 'Sie haben die Serienleerung für',
                and: 'und',
                nextEmptyingWillTakePlaceOn: 'Die nächste Leerung erfolgt am',
                emptyingOverviewInfo: 'Die Leerungsübersicht finden Sie im Kalender.',
                hasBeenOrdered: ' ist bestellt. ',
                itWillTakePlaceOn: 'Sie erfolgt am ',
                cancelingInformation: 'Sie können Ihre Bestellung bis 22:00 Uhr am Vorabend stornieren (auch an Sonn- und Feiertagen).',
                notificationsSettingsReminder: 'Vergessen Sie nicht Ihre Benachrichtigungseinstellungen auf der Startseite zu verwalten.',
                lidOnIt: 'Deckel drauf!'
            },
            paymentModal: {
                header: 'Zahlungsmittel wählen',
                payWithCreditBtn: 'Bezahlen',
                creditAccount: 'Guthabenkonto',
                invoice: 'Rechnung',
                creditCard: 'Kreditkarte',
                maestro: 'Maestro',
                postFinance: 'PostFinance',
                twint: 'Twint',
            }
        },
        balances: {
            header: "Guthaben & Transaktionen",
            tabs: {
                creditAccount: 'Guthabenkonto',
                bills: 'Rechnungen',
                confirmationOfEmptying: 'Leerungsbelege',
            },
            credit: 'Guthaben',
            emptyings: 'Leerungen',
            pastTransactions: {
                title: 'Vergangene Kontoaktivitäten',
                activityTypes: {
                    deposit: 'Aufladung',
                    emptyingCancelled: 'Stornierung',
                    payout: 'Auszahlung',
                    requestedEmptying: 'Bestellung'
                }
            },
            date: 'Datum',
            issuer: 'Mitarbeitender',
            activity: 'Aktivität',
            amountChf: 'Betrag (CHF)',
            status: 'Status',
            remainingCreditChf: 'Guthabenstand (CHF)',
            chf: 'CHF',
            service: 'Leistung',
            overview: 'Übersicht',
            name: 'Name',
            code: 'Code',
            id: 'ID',
            paymentMethod: 'Zahlungsmittel',
            invoiceNumber: 'Q.-/R.-Nr.',
            file: 'Datei',
            creditCard: 'Kreditkarte',
            time: 'Uhrzeit',
            documentNotReadyYet: 'Ihre Rechnung wurde noch nicht generiert. Dies kann einige Minuten dauern.'
        },
        myData: {
            header: 'Meine Daten',
            personalDetails: {
                header: 'Personalien',
                salutation: 'Anrede',
                surname: 'Name',
                firstName: 'Vorname',
                email: 'E-Mail',
                loginName: 'Login',
                mobilePhone: 'Telefon (Mobil)',
                salutationOptions: {
                    mr: 'Herr',
                    mrs: 'Frau'
                }
            },
            companyData: {
                header: 'Firmendaten',
                tooltip: 'Sie können eine Datenänderung bei der KVA Thurgau beantragen.',
                customerNumber: 'Kundennummer',
                company: 'Firma',
                street: 'Strasse',
                no: 'Nr.',
                location: 'Ort',
                postcode: 'PLZ',
                email: 'E-Mail',
                phone: 'Telefon',
                requestDataChange: 'Datenänderung beantragen',
                requestDataChangeModal: {
                    header: 'Datenänderung beantragen',
                    currentCompanyData: 'Aktuelle Firmendaten',
                    newCompanyData: 'Neue Firmendaten',
                    infoText: 'Ihre Daten werden innerhalb von 24 Stunden von der KVA Thurgau geprüft und freigegeben.',
                }
            }
        },
        myOrganization: {
            header: 'Meine Organisation',
            employees: 'Mitarbeitende',
            surname: 'Name',
            firstName: 'Vorname',
            email: 'E-Mail',
            rights: 'Rechte',
            container: 'Container',
            status: 'Status',
            addEmployees: 'Mitarbeitende hinzufügen',
            editEmployees: 'Mitarbeitenden bearbeiten',
            updatingEmployee: 'Mitarbeiter wird aktualisiert...',
            creatingInvitation: 'Es wird grade eine neue Einladung erstellt',
            addingEmployeesNotPossibleDueToNoActiveContainer: 'Mitarbeitende können erst hinzugefügt werden, sobald mindestens ein Container aktiviert ist.',
            invitationForm: {
                header: 'Mitarbeitende einladen',
                personalDetails: 'Personalien',
                salutation: 'Anrede',
                mr: 'Herr',
                mrs: 'Frau',
                surname: 'Name',
                firstName: 'Vorname',
                email: 'E-Mail',
                role: 'Rechte',
                roleTooltip: 'Legen Sie die Rechte Ihrer Mitarbeitenden fest. Administrator: Mitarbeitende können einsehen, wann Leerungen erfolgen, sie können Leerungen bestellen und stornieren, das Guthaben aufladen und neue Mitarbeitende einladen. Mitarbeitende (bestellberechtigt): Mitarbeitende können einsehen, wann Leerungen erfolgen und Leerungen bestellen und stornieren. Mitarbeitende (nur Einsehen): Mitarbeitende können das Portal einsehen, haben jedoch keine Bestellberechtigung.',
                pleaseChoose: 'Bitte wählen',
                containerAssignment: 'Container-Zuweisung',
                choice: 'Auswahl: ',
                creatingInvitation: 'Einladung erstellen...',
                atLeastOneContainer: 'Es muss mindestens ein Container ausgewählt werden.',
            },
            invitationSentModal: {
                header: 'Einladung versendet',
                andOffYouGo: 'Und ab die Post!',
                yourEmployeeWillReceiveEmail: 'In Kürze erhält Ihr Mitarbeitender eine Registrierungs-E-Mail.',
                validityHint: "Die Einladung ist für 3 Tage gültig. Nach Ablauf der Gültigkeit können Sie die Einladung manuell aktualisieren."
            },
            deleteEmployeeModal: {
                header: 'Mitarbeitenden löschen',
                confirmDeleteText: 'Wollen Sie den Mitarbeitenden <1>{{textValue}}</1> wirklich löschen?',
                removing: "Entfernen..."
            },
        },
        myContainers: {
            header: 'Meine Container',
            overview: 'Übersicht',
            name: 'Name',
            id: 'ID',
            code: 'Code',
            street: 'Strasse',
            number: 'Nr.',
            location: 'Ort',
            postcode: 'PLZ',
            color: 'Farbe',
            status: 'Status',
            containerAddOrEditForm: {
                registerContainer: 'Container anmelden',
                editContainer: 'Container bearbeiten',
                sameLocationText: 'Gleicher Standort wie Rechnungsadresse?',
                yes: 'Ja',
                no: 'Nein',
                containerName: 'Container-Name',
                containerNamePlaceholder: 'z.B. Container_1',
                containerId: 'Container-ID',
                containerIdTooltip: 'Die KVA Thurgau teilt Ihnen nach erfolgreicher Prüfung automatisch eine Container-ID zu.',
                containerCode: 'Container-Code',
                containerCodeTooltip: 'Definieren Sie einen individuellen, zweistelligen Code zur Identifikation Ihres Containers im e-Container Portal.',
                containerCodePlaceholder: 'z.B. F1',
                color: 'Farbe',
                colors: {
                    blue: 'Blau',
                    green: 'Grün',
                    orange: 'Orange',
                    red: 'Rot',
                    purple: 'Violett',
                },
                colorTooltip: 'Farbliche Darstellung des Containers im Portal zur individuellen Gestaltung.',
                street: 'Strasse',
                number: 'Nr.',
                location: 'Ort',
                postcode: 'PLZ',
                photo: 'Foto (optional)',
                dropFileOrBrowseFolder: 'Datei ablegen oder Ordner <0>durchsuchen</0>.',
                comments: 'Anmerkungen (optional)',
                addressChangeTooltip: 'Wenn sich Ihre Adresse ändert, müssen Sie Ihren Container neu registrieren.'
            },
            containerCheckedModal: {
                header: 'Container wird geprüft',
                weAreAlreadyThere: 'Wir sind schon dabei!',
                afterSuccessfulCheckText: 'Nach erfolgreicher Prüfung werden Sie benachrichtigt.',
                infoText: 'Die Prüfung dauert in der Regel 2 Arbeitstage. Leerungen können Sie nur für aktive Container bestellen.'
            },
            deleteContainersModal: {
                deleteContainer: "Container löschen",
                doYouReallyWantToDeleteContainer: 'Wollen Sie <1>{{textValue}}</1> wirklich löschen?',
                scheduledEmptyingFound: 'Für diesen Container wurden geplante Leerungen gefunden. Eine Löschung ist nicht möglich.'
            }
        },
        help: {
            headers: {
                help: 'Hilfe',
                helpAndContact: 'Hilfe & Kontakt',
            },
            tabs: {
                guides: 'Anleitungen',
                faq: 'Häufige Fragen',
                contact: 'Kontakt',
            },
            guides: {
                header1: 'Erfolgreich starten',
                subheader1: 'Lernen Sie alles über die ersten Schritte im e-Container Portal.',
                header2: 'Effizient organisieren',
                subheader2: 'Lernen Sie Funktionalitäten kennen, die Ihre Arbeit rund um die Containerleerung effizienter machen.',
                downloadPdf: 'Download PDF',
            },
            faq: 'Häufige Fragen',
            contact: {
                header: 'Kontaktformular',
                subheader: 'Falls Sie die Antworten auf Ihre Fragen nicht im FAQ- und Anleitungsbereich gefunden haben, füllen Sie das nachfolgende Kontaktformular aus und wir kümmern uns persönlich um Ihr Anliegen.',
                firstName: 'Vorname*',
                lastName: 'Name*',
                company: 'Firma',
                streetAndNo: 'Strasse, Nr.',
                zipAndCity: 'PLZ, Ort',
                phone: 'Telefon',
                email: 'E-Mail*',
                category: 'Kategorie*',
                pleaseChoose: 'Bitte wählen',
                portal: 'Portal',
                emptying: 'Leerung',
                credit: 'Guthaben',
                invoice: 'Rechnung',
                feedback: 'Feedback',
                misc: 'Sonstiges',
                yourMessage: 'Ihre Nachricht*',
                messageSentText: 'Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich um Ihr Anliegen kümmern!',
                postingContactForm: 'Ihre Anfrage wird gesendet...',
            }
        },
        invitationRedemption: {
            isLoading: "Ihre Einladung wird geprüft. Bitte warten Sie...",
            isSuccess: "Ihre Einladung wurde akzeptiert. Sie werden in wenigen Momenten auf die Startseite weitergeleitet..."
        },
        pageNotFound: {
            header: '404: Seite nicht gefunden',
            paragraph: 'Hoppla, da ist etwas schiefgelaufen! Die gesuchte Seite existiert leider nicht (mehr). Möglicherweise ist der Link, auf den Sie geklickt haben, nicht mehr aktuell. Bitte kehren Sie auf die Startseite zurück.',
        },
        genericAppErrorPage: {
            header: 'Es ist ein unbekannter Fehler aufgetreten',
            report: 'Meldung: ',
            reloadApplication: 'Es kann sein, dass das Problem durch das erneute Laden der Seite gelöst wird. Falls der Fehler dann immer noch besteht, schliessen Sie den Browser und starten die Applikation neu.',
        },
        unauthenticatedPage: {
            youAreNotLoggedIn: 'Sie sind nicht angemeldet. Sie werden zur Anmeldeseite weitergeleitet.',
            noPermissionsHeader: '401: Nicht autorisiert',
            noPermissionsParagraph: 'Sie verfügen nicht über die Berechtigung, auf diese Website zuzugreifen.',
        }
    },
    errors: {
        http: {
            unknown: 'Unbekannter Datenfehler',
            userNotLoggedIn: 'Benutzer muss angemeldet sein',
            accountAssignmentNotFound: 'Der Benutzer ist für den angegebenen Kunden nicht freigeschaltet',
            unsatisfiedPaymentRole: 'Für diese Aktion muss das Recht \'Zahlung\' zugewiesen sein',
            unsatisfiedPermissionRole: 'Für diese Aktion muss das Recht \'Berechtigung\' zugewiesen sein',
            unsatisfiedContainerRole: 'Für diese Aktion muss das Recht \'Container\' zugewiesen sein',
            invitationNotFound: 'Die Einladung wurde nicht gefunden',
            invitationAlreadyRedeemed: 'Dieser Registrierungslink wurde bereits verwendet.',
            invitationExpiredDetail: 'Gerne reaktivieren wir Ihren Registrierungslink. Schreiben Sie uns hierfür eine E-Mail an e-container@kvatg.ch oder rufen Sie uns unter der 071 626 96 00 zu unseren Öffnungszeiten an (Mo - Fr, 07:30 - 12:00 und 13:30 - 17:00 Uhr). ',
            invitationExpired: 'Dieser Registrierungslink ist abgelaufen.',
            invitationAlreadyRedeemedDetail: 'Sie sind bereits registriert? Dann melden Sie sich über www.e-container.ch direkt im Portal an. <br/>Andernfalls wenden Sie sich an den Administrator Ihrer Firma, damit dieser Sie dem Firmenaccount hinzufügen kann. ',
            scheduleEmptyings: {
                containsAlreadyScheduledDates: 'Es wurde versucht, bereits eingeplante Leerungsdaten erneut zu buchen',
                creditProcessingStillOnGoing: 'Aktuell wird bereits eine andere Aktion verarbeitet. Bitte versuchen Sie es später noch einmal.',
                datesMustBeInFuture: 'Die Daten der Leerungen müssen in der Zukunft sein',
                disallowedDatesProvided: 'Die angegebenen Daten sind ungültig',
                unsufficientBookableCredit: 'Auf Ihrem Konto ist zu wenig Guthaben vorhanden.'
            }
        },
        requiredField: 'Pflichtfeld'
    },
    buttons: {
        edit: 'Bearbeiten',
        delete: 'Löschen',
        abort: 'Abbrechen',
        cancel: 'Stornieren',
        next: 'Weiter',
        goToHome: "Zur Startseite",
        saveSelection: 'Auswahl speichern',
        charge: 'Aufladen',
        changePassword: 'Passwort ändern',
        save: 'Speichern',
        invite: "Einladen",
        plan: 'Planen',
        addContainers: "Container hinzufügen",
        register: 'Anmelden',
        yes: 'Ja',
        no: 'Nein',
        resetToDefault: 'Zurücksetzen',
        close: 'Schliessen',
        back: 'Zurück',
        home: 'Startseite',
        request: 'Beantragen',
        send: 'Senden',
        refreshPage: 'Seite neu laden',
    },
    toasts: {
        saved: 'Gespeichert!',
        planned: 'Geplant!',
        error: 'Fehler!',
        dataChangeRequested: 'Datenänderung beantragt!',
        requestSent: 'Anfrage gesendet!',
        topUpSucceeded: 'Die Aufladung Ihres Guthabens war erfolgreich',
        topUpFailed: 'Der Aufladung Ihres Guthabens wurde abgebrochen',
        emptyingCancelled: 'Leerung storniert!'
    },
    pills: {
        active: 'Aktiv',
        pending: 'Ausstehend',
        cancelled: 'Abgesagt',
        completed: 'Vollendet',
        uncompleted: 'Nicht vollendet',
        emptied: 'Geleert',
        notemptied: 'Nicht geleert',
        booked: 'Gebucht',
        expired: 'Abgelaufen',
        invited: 'Eingeladen'
    },
    dates: {
        workingDays: {
            short: ['Mo', 'Di', 'Mi', 'Do', 'Fr'],
            long: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag']
        },
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
    },
    containerStatus: {
        active: 'Aktiv', 
        pending: 'In Bearbeitung'
    },
    roles: {
        admin: 'Administrator',
        staff: 'Mitarbeitende (bestellberechtigt)',
        viewer: 'Mitarbeitende (nur einsehen)'
    }
}