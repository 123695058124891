import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { IconBalances, IconInvoice, IconCreditCard, IconMaestro, IconPostFinance, IconTwint, IconCircleCheckSelected } from "../Icons";
import { ExternalPaymentMethod, PaymentMethod } from "../../types/PaymentMethod";
import { styles } from "../../styles";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";

const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
    width: 100%;
    justify-content: flex-start; 
`;

const Card = styled.div<{ selected?: boolean, isLocked?: boolean }>`
    flex: 1 1 calc(33.333% - 8px);
    max-width: calc(33.333% - 8px);
    min-width: 142.5px;
    height: 98px;
    padding: 24px 16px;
    background-color: ${({ isLocked }) => isLocked ? '#FCFCFC' : 'white'};
    border: ${({ selected }) => selected ? '1px solid #3371EA' : `1px solid ${styles.neutral200}`};
    border-radius: 4px;
    cursor: ${({ isLocked }) => isLocked ? 'not-allowed !important' : 'pointer'};
    box-shadow: ${({ selected, isLocked }) => (selected || isLocked) ? 'none' : styles.boxShadowBottomLight};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${styles.textColorSecondary700};
    position: relative;
    transition: all 0.1s ease-in;
    top: 0px;

    @media (max-width: 480px) {
        flex: 1 1 calc(50% - 8px);
        max-width: calc(50% - 8px);
    }

    &:hover {
        box-shadow: ${({ selected, isLocked }) => (selected || isLocked) ? 'none' : styles.boxShadowContainerHovered};
        top: ${({ selected, isLocked }) => (selected || isLocked) ? '0px' : '-8px'};        
    }

    svg {
        margin-bottom: 4px;
    }
`;

const TopRow = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    padding: 8px;
`;

type PaymentMethodChooserProps = {
    creditEnabled: boolean;
    invoiceEnabled: boolean;
    hideCredit?: boolean;
    disabled: boolean;
    onUpdate: (method?: PaymentMethod) => void;
}

export const PaymentMethodChooser = ({ creditEnabled, disabled, invoiceEnabled, onUpdate, hideCredit }: PaymentMethodChooserProps) => {
    const userContext = useContext(UserContext);
    const currentAccountId = userContext.accessPermission?.accountRef.id;
    const schaefliAccountId = "29490226-f3e1-ee11-904c-0022480fc8cb";

    const [selectedMethod, _setSelectedMethod] = useState<PaymentMethod>();
    const { t } = useTranslation();
    useEffect(() => {
        onUpdate(selectedMethod);
    }, [onUpdate, selectedMethod]);

    const updateSelectedMethod = (paymentMethod: PaymentMethod) => {
        if (!disabled) {
            _setSelectedMethod(paymentMethod)
        }
    }
    return (
        <>
            <Cards>
                {!hideCredit && <Card isLocked={!creditEnabled} selected={selectedMethod === 'Guthaben'} onClick={(e) => !creditEnabled ? e.preventDefault() : updateSelectedMethod('Guthaben')}>
                    <TopRow>
                        {selectedMethod === 'Guthaben' && <IconCircleCheckSelected />}
                    </TopRow>
                    <IconBalances />
                    {t("pages.emptying.paymentModal.creditAccount")}
                </Card>}
                {invoiceEnabled &&
                    <Card selected={selectedMethod === 'Rechnung'} onClick={() => updateSelectedMethod('Rechnung')}>
                        <TopRow>
                            {selectedMethod === 'Rechnung' && <IconCircleCheckSelected />}
                        </TopRow>
                        <IconInvoice />
                        {t("pages.emptying.paymentModal.invoice")}
                    </Card>
                }
                <Card selected={selectedMethod === ExternalPaymentMethod.Twint} onClick={() => updateSelectedMethod(ExternalPaymentMethod.Twint)}>
                    <TopRow>
                        {selectedMethod === ExternalPaymentMethod.Twint && <IconCircleCheckSelected />}
                    </TopRow>
                    <IconTwint />
                    {t('pages.emptying.paymentModal.twint')}
                </Card>

                { currentAccountId === schaefliAccountId &&
                    <>
                        <Card selected={selectedMethod === ExternalPaymentMethod.CreditCard} onClick={() => updateSelectedMethod(ExternalPaymentMethod.CreditCard)}>
                            <TopRow>
                                {selectedMethod === ExternalPaymentMethod.CreditCard && <IconCircleCheckSelected />}
                            </TopRow>
                            <IconCreditCard />
                            {t('pages.emptying.paymentModal.creditCard')}
                        </Card>
                        <Card selected={selectedMethod === ExternalPaymentMethod.Maestro} onClick={() => updateSelectedMethod(ExternalPaymentMethod.Maestro)}>
                            <TopRow>
                                {selectedMethod === ExternalPaymentMethod.Maestro && <IconCircleCheckSelected />}
                            </TopRow>
                            <IconMaestro />
                            {t('pages.emptying.paymentModal.maestro')}
                        </Card>
                        <Card selected={selectedMethod === ExternalPaymentMethod.PostFinance} onClick={() => updateSelectedMethod(ExternalPaymentMethod.PostFinance)}>
                            <TopRow>
                                {selectedMethod === ExternalPaymentMethod.PostFinance && <IconCircleCheckSelected />}
                            </TopRow>
                            <IconPostFinance />
                            {t('pages.emptying.paymentModal.postFinance')}
                        </Card>
                    </>
                }
            </Cards>
        </>
    )
}